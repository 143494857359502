body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


.menulink {
  font-weight: bold !important;

}
.hideTab {
  display: none !important;
}

.ui.menu:first-child {
  margin-bottom: 0;
}
.hideTab {
  display: none;
}
.showit {
  display: block;
}
.display-linebreak {
  white-space: pre-line;
}
.rt-resizable-header {
  font-weight: bold;

}
.-sort-asc {
  box-shadow: inset 0 3px 0 0 black !important;
}
.-sort-desc {
  box-shadow: inset 0 3px 0 0 black !important;
}
.ui.menu .item {
  font-weight: bold !important;

}
.react-confirm-alert-overlay {
  z-index: 99999 !important;
}
.hiddenTab {
  display: none !important;
}
.mce-container { z-index: 65537000 !important; }
.ui.modal > .content {
  padding: 5px !important;
}
.ui.large.modal {
  margin-left: -570px !important;
}
.ui.small.modal {
  margin-left: -380px !important;
}
.ui.large.modal > .header {
  font-size: 120% !important;
}
.ui.tabular.menu .active.item {
  background-color: #FFC20E !important;
}
.ui.menu .active.item {
  background-color: #FFC20E !important;
}
#notification-wrapper {
  z-index: 6500 !important;
  position: relative !important;
}
.status_draft, .status_archived { color: #fff; padding: 0 .5em; background-color: #888; }
.status_complete { color: #fff; padding: 0 .5em; background-color: #088; }
.status_submitted, .status_solicitation { color: #fff; padding: 0 .5em; background-color: #880; }
.status_rejected { color: #fff; padding: 0 .5em; background-color: #800; }
.status_working { color: #fff; padding: 0 .5em; background-color: #008; }
.status_approved { color: #fff; padding: 0 .5em; background-color: #808; }
.status_executed { color: #fff; padding: 0 .5em; background-color: #080; }
.ui.segment[class*="bottom attached"] {
  margin: 0 !important;
  padding-top: 5px !important;
}
.ui.menu .item {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.ui.menu:not(.vertical) .item > .button {
  margin-right: 5px !important;
}
.ui.menu {
  margin-bottom: 0;
}
.dropzone {
  background: lightgray;
  width: 550px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
}

.dz-preview {
  display: none;
}
.rc-draggable-list-handles {
  float: left;
}
.rst__nodeContent {
  width: 500px;
  padding: 0;
  margin: 0;
}
.rule,
.ruleGroup .ruleGroup {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.ruleGroup {
  padding: 0.5rem;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: rgba(255, 233, 172, 0.5);
}
.rst__rowWrapper {
  padding: 0 !important;

}
.rst__rowSubtitle {
  display: block !important;
}

.rst__lineBlock, .rst__absoluteLineBlock {
  display: none !important;
}
.nospace {
  margin-bottom: 0 !important;
}
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -webkit-grab;
}
.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
  border: 1px solid black !important;
  background-color: darkgray !important;
}


/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}
.clickable {
  cursor: move; /* fallback if grab cursor is unsupported */
 }

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.clickable:active {
  cursor: move;
}
.ruleGroup-addRule  {
  color: white !important;
  background-color: green !important;
  text-transform: none !important;
  height: 25px !important;
  line-height: 25px !important;
  border-radius: 8px;
  margin-left: 5px;
}
.ruleGroup-addGroup {
  color: white !important;
  background-color: red !important;
  text-transform: none !important;
  height: 25px !important;
  line-height: 25px !important;
  border-radius: 8px;
  margin-left: 5px;
}
.rule-remove {
  color: white !important;
  background-color: red !important;
  text-transform: none !important;
  height: 25px !important;
  line-height: 25px !important;
  border-radius: 8px;
  margin-left: 5px;
}
.ruleGroup {
  background-color: lightgrey;
}.rule-operators {
  margin-right: 8px;
  margin-left: 8px;
 }
 .comment {
   border: 1px solid black;
   padding: 5px;
   margin-bottom: 10px;
 }

 .iconedit .tooltiptext {
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.iconedit:hover .tooltiptext {
  visibility: visible;
}
